var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data
    ? _c(
        "b-card",
        {
          staticClass: "card card-congratulations",
          attrs: { "text-variant": "center" }
        },
        [
          _c("b-img", {
            staticClass: "congratulations-img-left",
            attrs: { src: require("@/assets/images/elements/decore-left.png") }
          }),
          _c("b-img", {
            staticClass: "congratulations-img-right",
            attrs: { src: require("@/assets/images/elements/decore-right.png") }
          }),
          _c(
            "b-avatar",
            {
              staticClass: "shadow mb-2",
              attrs: { variant: "primary", size: "70" }
            },
            [_c("feather-icon", { attrs: { size: "28", icon: "AwardIcon" } })],
            1
          ),
          _c("h1", { staticClass: "mb-1 mt-50 text-white" }, [
            _vm._v(" Congratulations " + _vm._s(_vm.data.name) + ", ")
          ]),
          _c("b-card-text", { staticClass: "m-auto w-75" }, [
            _vm._v(" You have done "),
            _c("strong", [_vm._v(_vm._s(_vm.data.saleToday) + "%")]),
            _vm._v(" more sales today. Check your new badge in your profile. ")
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }