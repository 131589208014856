var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data
    ? _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "b-card-header",
            [
              _c("b-card-title", { staticClass: "ml-25" }, [
                _vm._v(" User Timeline ")
              ])
            ],
            1
          ),
          _c(
            "b-card-body",
            [
              _c(
                "app-timeline",
                [
                  _c(
                    "app-timeline-item",
                    { attrs: { variant: "primary" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1"
                        },
                        [
                          _c("h6", [_vm._v(_vm._s(_vm.data.step1.title))]),
                          _c(
                            "small",
                            {
                              staticClass:
                                "timeline-item-time text-nowrap text-muted ml-1"
                            },
                            [_vm._v(_vm._s(_vm.data.step1.duration))]
                          )
                        ]
                      ),
                      _c("p", [_vm._v(_vm._s(_vm.data.step1.subtitle))]),
                      _c(
                        "b-media",
                        { attrs: { "no-body": "" } },
                        [
                          _c(
                            "b-media-aside",
                            { staticClass: "mr-1" },
                            [
                              _c("b-img", {
                                attrs: {
                                  src: _vm.data.step1.img,
                                  height: "23",
                                  alt: _vm.data.step1.fileName
                                }
                              })
                            ],
                            1
                          ),
                          _c("b-media-body", { staticClass: "my-auto" }, [
                            _c("h6", { staticClass: "media-body mb-0" }, [
                              _vm._v(
                                " " + _vm._s(_vm.data.step1.fileName) + " "
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "app-timeline-item",
                    { attrs: { variant: "warning" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1"
                        },
                        [
                          _c("h6", [_vm._v(_vm._s(_vm.data.step2.title))]),
                          _c(
                            "small",
                            {
                              staticClass:
                                "timeline-item-time text-nowrap text-muted ml-1"
                            },
                            [_vm._v(_vm._s(_vm.data.step2.duration))]
                          )
                        ]
                      ),
                      _c("p", [_vm._v(_vm._s(_vm.data.step2.subtitle))]),
                      _c(
                        "b-media",
                        { attrs: { "no-body": "" } },
                        [
                          _c(
                            "b-media-aside",
                            { staticClass: "mr-50" },
                            [
                              _c("b-avatar", {
                                attrs: {
                                  src: _vm.data.step2.avatar,
                                  size: "38"
                                }
                              })
                            ],
                            1
                          ),
                          _c("b-media-body", { staticClass: "my-auto" }, [
                            _c("h6", { staticClass: "mb-0" }, [
                              _vm._v(
                                " " + _vm._s(_vm.data.step2.avatarName) + " "
                              )
                            ]),
                            _c("p", { staticClass: "mb-0" }, [
                              _vm._v(
                                " " + _vm._s(_vm.data.step2.occupation) + " "
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "app-timeline-item",
                    { attrs: { variant: "info" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1"
                        },
                        [
                          _c("h6", [_vm._v(_vm._s(_vm.data.step3.title))]),
                          _c(
                            "small",
                            {
                              staticClass:
                                "timeline-item-time text-nowrap text-muted ml-1"
                            },
                            [_vm._v(_vm._s(_vm.data.step3.duration))]
                          )
                        ]
                      ),
                      _c("p", [_vm._v(_vm._s(_vm.data.step3.subtitle))]),
                      _c(
                        "b-avatar-group",
                        { attrs: { size: "35px" } },
                        _vm._l(_vm.data.step3.avatars, function(avatar) {
                          return _c("b-avatar", {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover.top",
                                value: "Tooltip!",
                                expression: "'Tooltip!'",
                                modifiers: { hover: true, top: true }
                              }
                            ],
                            key: avatar.userImg,
                            staticClass: "pull-up",
                            attrs: { src: avatar.userImg }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c("app-timeline-item", {
                    attrs: {
                      title: _vm.data.step4.title,
                      subtitle: _vm.data.step4.subtitle,
                      time: _vm.data.step4.duration,
                      variant: "danger"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }