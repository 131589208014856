var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data
    ? _c(
        "b-card",
        { staticClass: "card-app-design" },
        [
          _c("b-badge", { attrs: { variant: "light-primary" } }, [
            _vm._v(" " + _vm._s(_vm.data.date) + " ")
          ]),
          _c("b-card-title", { staticClass: "mt-1 mb-75" }, [
            _vm._v(" " + _vm._s(_vm.data.title) + " ")
          ]),
          _c("b-card-text", { staticClass: "font-small-2 mb-2" }, [
            _vm._v(" " + _vm._s(_vm.data.subtitle) + " ")
          ]),
          _c(
            "div",
            { staticClass: "design-group" },
            [
              _c("h6", { staticClass: "section-label" }, [_vm._v(" Team ")]),
              _vm._l(_vm.data.teams, function(team) {
                return _c(
                  "b-badge",
                  {
                    key: team.color,
                    staticClass: "mr-1",
                    attrs: { variant: team.color }
                  },
                  [_vm._v(" " + _vm._s(team.name) + " ")]
                )
              })
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "design-group" },
            [
              _c("h6", { staticClass: "section-label" }, [_vm._v(" Members ")]),
              _vm._l(_vm.data.members, function(member, index) {
                return _c("b-avatar", {
                  key: index,
                  staticClass: "mr-1",
                  attrs: {
                    variant: member.color,
                    text: member.text,
                    src: member.img,
                    size: "34"
                  }
                })
              })
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "design-planning-wrapper" },
            _vm._l(_vm.data.planing, function(plan) {
              return _c(
                "div",
                { key: plan.title, staticClass: "design-planning" },
                [
                  _c("p", { staticClass: "card-text mb-25" }, [
                    _vm._v(" " + _vm._s(plan.title) + " ")
                  ]),
                  _c("h6", { staticClass: "mb-0" }, [
                    _vm._v(" " + _vm._s(plan.subtitle) + " ")
                  ])
                ]
              )
            }),
            0
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(255, 255, 255, 0.15)",
                  expression: "'rgba(255, 255, 255, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { variant: "primary", block: "" }
            },
            [_vm._v(" Join Team ")]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }